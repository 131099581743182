<template>
  <div>
    <h1>View User Trips</h1>
  </div>

</template>

<script>
  export default {}
</script>
